import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface BidRate {
  costPerDistance: number;
  costPerHour: number;
  baseFare: number;
}

export interface bidRatesState {
  local: {
    data: BidRate[];
    loading: boolean;
    error: string | null;
    rates: BidRate;
  };
  outlying: {
    data: BidRate[];
    loading: boolean;
    error: string | null;
    rates: BidRate;
  };
  baseRates: BaseRate[];
  filters: BidRateFilters;
}

export interface BaseRate {
  // base rate properties
}

export interface BidRateFilters {
  // filter properties
}

export const initialState: bidRatesState = {
  local: {
    data: [],
    loading: false,
    error: null,
    rates: {
      costPerDistance: 1,
      costPerHour: 2,
      baseFare: 3,
    },
  },
  outlying: {
    data: [],
    loading: false,
    error: null,
    rates: {
      costPerDistance: 1,
      costPerHour: 2,
      baseFare: 3,
    },
  },
  baseRates: [],
  filters: {},
};

export const sliceName = 'bidRates';

const bidRates = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    updateLocalBidRate(state, action: PayloadAction<BidRate>) {
      state.local.rates = action.payload;
    },
    updateOutlyingBidRate(state, action: PayloadAction<BidRate>) {
      state.outlying.rates = action.payload;
    },
  },
});

export const { updateLocalBidRate, updateOutlyingBidRate } = bidRates.actions;

export default bidRates.reducer;
