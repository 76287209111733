import { InterfaceAddVehicleForm } from '@interfaces/InterfaceAddVehicleForm.ts';
import { InterfaceGenericMap } from '@interfaces/InterfaceGenericMap.ts';
import { InterfaceGenericTypeSlug } from '@interfaces/InterfaceGenericTypeSlug.ts';
import { InterfaceVehicle } from '@interfaces/InterfaceVehicle.ts';
import { InterfaceVehicleFleet } from '@interfaces/InterfaceVehicleFleet.ts';
import { InterfaceVehicleModel } from '@interfaces/InterfaceVehicleModel.ts';
import { InterfaceVehicleSizeType } from '@interfaces/InterfaceVehicleSizeType.ts';
import { createSlice } from '@reduxjs/toolkit';
import { AppState } from '@store/appStore.ts';
import { vehicleSizeImageUrls } from '@store/reducers/data/vehicleSizeImageUrls.ts';
import { meLogOut } from '@store/reducers/meReducer.ts';
import createDebouncedAsyncThunk from '@store/reducers/reducerHelpers/createDebouncedAsyncThunk.ts';
import { sliceStateCheckAndUpdate } from '@store/reducers/reducerHelpers/sliceStateCheckAndUpdate.ts';
import thunkDelete from '@store/reducers/reducerHelpers/thunkDelete.ts';
import { thunkGet } from '@store/reducers/reducerHelpers/thunkGet.ts';
import { defaultTypeDebounceTimeMs } from '@store/reducers/reducerHelpers/thunkHelperShared.ts';
import { thunkPost } from '@store/reducers/reducerHelpers/thunkPost.ts';
import { InterfaceOption, getOptionsArrayFromUuidOrSlug } from '@utils/OptionsArrayHelpers.ts';
import memoize from 'lodash-es/memoize';

export interface VehicleReducerState {
  vehicleMakeTypes: InterfaceGenericMap<InterfaceGenericTypeSlug>;
  vehicleModels: InterfaceGenericMap<InterfaceVehicleModel>;
  vehicleSizeTypes: InterfaceGenericMap<InterfaceVehicleSizeType>;
  vehicleStatusTypes: InterfaceGenericMap<InterfaceGenericTypeSlug>;
  vehicleFeatureTypes: InterfaceGenericMap<InterfaceGenericTypeSlug>;
  vehicleColourTypes: InterfaceGenericMap<InterfaceGenericTypeSlug>;
  vehicleStatusTypeColourMap: {
    [vehicleStatusTypeSlug: string]: string;
  };
  vehicleTypes: InterfaceGenericMap<InterfaceGenericTypeSlug>;
  vehicles: InterfaceGenericMap<InterfaceVehicle>;
  fleets: InterfaceGenericMap<InterfaceVehicleFleet>;
  vehicleSizeImageUrls: InterfaceGenericMap<string>;
}

const initialState: VehicleReducerState = {
  vehicleMakeTypes: {},
  vehicleModels: {},
  vehicleSizeTypes: {},
  vehicleStatusTypes: {},
  vehicleColourTypes: {},
  vehicleFeatureTypes: {},
  vehicleStatusTypeColourMap: {
    available: 'success',
    booked: 'cb_orange',
    maintenance: 'cb_red',
    offline: 'cb_red',
    'on-delivery': 'cb_lightblue',
    unavailable: 'cb_red',
  },
  vehicleTypes: {},
  vehicles: {},
  fleets: {},
  vehicleSizeImageUrls,
};

const sliceName = 'vehicle';

export const vehicleSlice = createSlice({
  name: sliceName, // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(meLogOut.fulfilled, () => initialState)
      .addCase(updateVehicleStatus.fulfilled, sliceStateCheckAndUpdate.sliceItemElementUuid('vehicles'))
      .addCase(getVehicleTypes.fulfilled, sliceStateCheckAndUpdate.sliceItem('vehicleTypes'))
      .addCase(getVehicleSizeTypes.fulfilled, sliceStateCheckAndUpdate.sliceItem('vehicleSizeTypes'))
      .addCase(getVehicleStatusTypes.fulfilled, sliceStateCheckAndUpdate.sliceItem('vehicleStatusTypes'))
      .addCase(getVehicleColourTypes.fulfilled, sliceStateCheckAndUpdate.sliceItem('vehicleColourTypes'))
      .addCase(getVehicleFeatureTypes.fulfilled, sliceStateCheckAndUpdate.sliceItem('vehicleFeatureTypes'))
      .addCase(getVehicleMakeTypes.fulfilled, sliceStateCheckAndUpdate.sliceItem('vehicleMakeTypes'))
      .addCase(getVehicleModels.fulfilled, sliceStateCheckAndUpdate.sliceItem('vehicleModels'))
      .addCase(getCompanyVehicles.fulfilled, sliceStateCheckAndUpdate.sliceItem('vehicles'))
      .addCase(getFleets.fulfilled, sliceStateCheckAndUpdate.sliceItem('fleets'))
      .addCase(addCompanyVehicle.fulfilled, sliceStateCheckAndUpdate.sliceItemElementUuid('vehicles'));
  },
});

export const getVehicleTypes = thunkGet.get(sliceName, `type/vehicle`);
export const getVehicleStatusTypes = thunkGet.get(sliceName, `type/vehicle/status`);
export const getVehicleSizeTypes = thunkGet.get(sliceName, `type/vehicle/size`);
export const getVehicleColourTypes = thunkGet.get(sliceName, `type/vehicle/colour`);
export const getVehicleFeatureTypes = thunkGet.get(sliceName, `type/vehicle/feature`);
export const getVehicleMakeTypes = thunkGet.get(sliceName, `type/vehicle/make`);
export const getVehicleModels = thunkGet.get(sliceName, `type/vehicle/model`);
export const getCompanyVehicles = thunkGet.compUrl(sliceName, `c/{companySlug}/admin/vehicle`);

export const getFleets = thunkGet.get(sliceName, 'c/{companySlug}/admin/fleet');

export const addCompanyVehicle = thunkPost.compUrl<InterfaceAddVehicleForm>(
  'vehicle',
  '/c/{companySlug}/admin/vehicle',
);

export interface InterfaceDelVehicleForm {
  vehicleUuid: string;
}
export const delVehicle = thunkDelete.compUrl<InterfaceDelVehicleForm>('vehicle', '/c/{companySlug}/admin/vehicle');

export interface InterfaceUpdateVehicleStatusForm {
  vehicleUuid: string;
  vehicleStatusTypeSlug: string;
}
export const updateVehicleStatus = thunkPost.compUrl<InterfaceUpdateVehicleStatusForm>(
  'vehicle',
  '/c/{companySlug}/admin/vehicle/status',
  [getCompanyVehicles()], // Get updated list of vehicles after change
);

export const selectVehicleState = (state: AppState) => state.vehicle;
export const selectVehicleStatusTypes = (state: AppState) => selectVehicleState(state).vehicleStatusTypes;
export const selectVehicleModels = (state: AppState) => selectVehicleState(state).vehicleModels;
export const selectVehicleSizeTypes = (state: AppState) => selectVehicleState(state).vehicleSizeTypes;
export const selectVehicleStatusTypeColourMap = (state: AppState) =>
  selectVehicleState(state).vehicleStatusTypeColourMap;
export const selectVehicleMakeTypes = (state: AppState) => selectVehicleState(state).vehicleMakeTypes;
export const selectVehicleMakeTypesOptions = (state: AppState): InterfaceOption[] =>
  getOptionsArrayFromUuidOrSlug(selectVehicleMakeTypes(state));
export const selectVehicleTypes = (state: AppState) => selectVehicleState(state).vehicleTypes;
export const selectVehicleTypesOptions = (state: AppState): InterfaceOption[] =>
  getOptionsArrayFromUuidOrSlug(selectVehicleTypes(state));
export const selectVehicles = memoize((state: AppState) => selectVehicleState(state).vehicles);
export const selectVehicleFeatureTypes = (state: AppState) => selectVehicleState(state).vehicleFeatureTypes;
export const selectVehicleColourTypes = (state: AppState) => selectVehicleState(state).vehicleColourTypes;
export const selectVehicleColorTypeOptions = (state: AppState): InterfaceOption[] =>
  getOptionsArrayFromUuidOrSlug(selectVehicleColourTypes(state));
export const selectVehicleArray = memoize((state: AppState): InterfaceVehicle[] =>
  Object.values(selectVehicles(state)),
);
export const selectVehicleFleets = (state: AppState) => selectVehicleState(state).fleets;
export const selectVehicleSizeImageUrls = (state: AppState) => selectVehicleState(state).vehicleSizeImageUrls;
export const selectVehicleSizeImage = (vehicleSizeTypeSlug: string) => (state: AppState) =>
  selectVehicleSizeImageUrls(state)[vehicleSizeTypeSlug] ?? selectVehicleSizeImageUrls(state)['unknown'];

// export const { } = vehicleSlice.actions;

export default vehicleSlice.reducer;

// Only for initial load of data that doesn't require authentication
// This is only done to speed things up. It is not required.
// This data should be reloaded in the pages that need it.
// Load any data a page needs in the page. Else it will not update ever.
export const loadInitialVehicleData = createDebouncedAsyncThunk(
  `${sliceName}/initial`,
  async (_, { dispatch }) => {
    dispatch(getVehicleTypes());
    dispatch(getVehicleStatusTypes());
    dispatch(getVehicleColourTypes());
    dispatch(getVehicleFeatureTypes());
    dispatch(getVehicleMakeTypes());
  },
  defaultTypeDebounceTimeMs,
);
