import { AsyncThunk, AsyncThunkAction } from '@reduxjs/toolkit';
import { AppState } from '@store/appStore.ts';
import { InterfaceThunkDeleteData } from '@store/reducers/reducerHelpers/thunkDelete.ts';
import { InterfaceThunkGetData } from '@store/reducers/reducerHelpers/thunkGet.ts';
import { InterfaceThunkPostData } from '@store/reducers/reducerHelpers/thunkPost.ts';

export const defaultTypeDebounceTimeMs = 500; // 0.5 seconds
export interface InterfaceFunctionReplace {
  [key: string]: (state: AppState) => string; // or any other function signature
}

export function replaceUrlState(
  url: string,
  replaceMap: InterfaceFunctionReplace,
  getState: () => AppState | unknown,
): string {
  let fullUrl: string = url;
  if (Object.keys(replaceMap).length > 0) {
    const state = getState() as AppState;
    Object.keys(replaceMap).forEach((key) => {
      fullUrl = fullUrl.replace(key, replaceMap[key](state));
    });
  }
  return fullUrl ?? '';
}

export interface InterfaceVarReplace {
  [key: string]: string; // or any other function signature
}

export function replaceUrlVariable(url: string, urlVarsReplaceMap: InterfaceVarReplace): string {
  let fullUrl: string = url;
  if (urlVarsReplaceMap !== undefined && Object.keys(urlVarsReplaceMap).length > 0) {
    Object.keys(urlVarsReplaceMap).forEach((key) => {
      fullUrl = fullUrl.replace(key, urlVarsReplaceMap[key]);
    });
  }
  return fullUrl ?? '';
}

export type DispatchAction =
  | { payload: any; type: string }
  | AsyncThunk<any, InterfaceThunkGetData | InterfaceThunkDeleteData | InterfaceThunkPostData | undefined | any, object>
  | AsyncThunkAction<any, any, any>
  | ((...args: any[]) => any);
