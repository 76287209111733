import logoDark from '@assets/images/cb_logo_dark.webp';
import logoLight from '@assets/images/logo_light.webp';
import { useAppSelector } from '@store/appStore.ts';
import { selectDefaultRoute } from '@store/reducers/meReducer.ts';
import { selectAppTheme } from '@store/reducers/themeConfigReducer.tsx';
import { NavLink } from 'react-router-dom';

export default function LogoNavLink() {
  const defaultRoute = useAppSelector(selectDefaultRoute);
  const themeConfig = useAppSelector(selectAppTheme);
  return (
    <NavLink to={defaultRoute} className="main-logo flex shrink-0 items-center">
      <img className="ml-[5px] w-48 flex-none" src={themeConfig.theme === 'light' ? logoLight : logoDark} alt="logo" />
    </NavLink>
  );
}
