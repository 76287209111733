import BlankLayout from '@components/Layouts/BlankLayout';
import DefaultLayout from '@components/Layouts/DefaultLayout';
import ErrorElement from '@pages/ErrorElement.tsx';
import { RoutesArray } from '@router/RoutesArray.tsx';
import { createBrowserRouter } from 'react-router-dom';

const finalRoutes = RoutesArray.map((route) => {
  return {
    ...route,
    element:
      route.layout === 'blank' ? (
        <BlankLayout>{route.element}</BlankLayout>
      ) : (
        <DefaultLayout>{route.element}</DefaultLayout>
      ),
    errorElement: <ErrorElement />,
  };
});

const Router = createBrowserRouter(finalRoutes);

export default Router;
