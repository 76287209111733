import { AppState } from '@/store/appStore';
import AppConfig from '@config/AppConfig.ts';
import { InterfaceAddress } from '@interfaces/InterfaceAddress.ts';
import { InterfaceCompany } from '@interfaces/InterfaceCompany.ts';
import { InterfaceUser } from '@interfaces/InterfaceUser.ts';
import { createSlice, SerializedError } from '@reduxjs/toolkit';
import { addSuccessNotification } from '@store/reducers/notificationReducer.ts';
import { meCheckIfLoggedIn } from '@store/reducers/reducerHelpers/meCheckIfLoggedIn.ts';
import { sliceStateCheckAndUpdate } from '@store/reducers/reducerHelpers/sliceStateCheckAndUpdate.ts';
import { thunkGet } from '@store/reducers/reducerHelpers/thunkGet.ts';
import { thunkPost } from '@store/reducers/reducerHelpers/thunkPost.ts';
import isEqual from 'lodash-es/isEqual';

export interface MeReducerState {
  isLoggedIn: boolean;
  currentCompanySlug: string | null;
  user: InterfaceUser | null;
  roles: string[];
  addresses: InterfaceAddress[];
  companies: InterfaceCompany[];
  status: 'idle' | 'pending' | 'succeeded' | 'failed';
  error: SerializedError | null;
  countryIsoCode: string | null;
}

const initialState: MeReducerState = {
  isLoggedIn: false,
  currentCompanySlug: null,
  user: null,
  roles: [],
  addresses: [],
  companies: [],
  status: 'idle',
  error: null,
  countryIsoCode: null,
};

export const sliceName = 'me';

export const meSlice = createSlice({
  name: sliceName, // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Use `extraReducers` to handle actions that were generated
    // _outside_ of the slice, such as thunks or in other slices
    builder
      .addCase(meLogOut.fulfilled, () => initialState)
      // Pass the generated action creators to `.addCase()`
      .addCase(meLogin.fulfilled, (state, action) => {
        // Same "mutating" update syntax thanks to Immer
        state.isLoggedIn = true;
        state.user = action.payload;
        state.currentCompanySlug = state.user?.defaultCompanySlug ?? null;
      })
      .addCase(meLogin.rejected, (state, action) => {
        state.isLoggedIn = false;
        state.user = null;
        state.error = action.error;
      })
      .addCase(meCheckIfLoggedIn.fulfilled, (state) => {
        if (!state.isLoggedIn) {
          state.isLoggedIn = true;
        }
      })
      .addCase(meCheckIfLoggedIn.rejected, (state) => {
        if (state.isLoggedIn) {
          state.isLoggedIn = false;
        }
      })
      .addCase(getMe.fulfilled, (state, action) => {
        const user: InterfaceUser = action.payload;
        if (!state.isLoggedIn) {
          state.isLoggedIn = true;
        }
        if (!isEqual(state.user, user)) {
          state.user = user;
        }
        if (state.currentCompanySlug !== user.defaultCompanySlug) {
          state.currentCompanySlug = user.defaultCompanySlug;
        }
      })
      .addCase(getMeRoles.fulfilled, sliceStateCheckAndUpdate.sliceItem('roles'))
      .addCase(getMeAddresses.fulfilled, sliceStateCheckAndUpdate.sliceItem('addresses'))
      .addCase(getMeCompanies.fulfilled, sliceStateCheckAndUpdate.sliceItem('companies'))
      .addCase(meUpdateName.fulfilled, sliceStateCheckAndUpdate.sliceItem('me'))
      .addCase(meUpdateEmail.fulfilled, sliceStateCheckAndUpdate.sliceItem('me'))
      .addCase(meUpdatePhoneNumber.fulfilled, sliceStateCheckAndUpdate.sliceItem('me'));
  },
});

export const getMe = thunkGet.get(sliceName, 'me');
export const getMeRoles = thunkGet.get(sliceName, 'me/role');
export const getMeAddresses = thunkGet.get(sliceName, 'me/address');
export const getMeCompanies = thunkGet.get(sliceName, 'me/company');

export interface InterfaceLoginForm {
  email: string;
  password: string;
}
export const meLogin = thunkPost.post<InterfaceLoginForm>('me', '/login', [
  getMe,
  getMeAddresses,
  getMeRoles,
  getMeCompanies,
]);

export const meLogOut = thunkPost.post('me', '/logout');

export interface InterfaceMeNameChangeForm {
  firstname: string;
  lastname: string;
}

export const meUpdateName = thunkPost.post<InterfaceMeNameChangeForm>('me', 'me/name', [
  addSuccessNotification({
    title: 'Name Changed',
    message: 'Name Changed Successfully',
  }),
]);

export interface InterfaceMeEmailChangeForm {
  email: string;
}

export const meUpdateEmail = thunkPost.post<InterfaceMeEmailChangeForm>('me', 'me/email');

export interface InterfaceMePhoneNumberChangeForm {
  countryIsoCode: string;
  phoneNumber: string;
}

export const meUpdatePhoneNumber = thunkPost.post<InterfaceMePhoneNumberChangeForm>('me', 'me/phonenumber');

export interface InterfaceMePasswordChangeForm {
  oldPassword: string;
  password: string;
}

export const meUpdatePassword = thunkPost.post<InterfaceMePasswordChangeForm>('me', 'me/password', [
  addSuccessNotification({
    title: 'Password Changed',
    message: 'Password Changed Successfully',
  }),
]);

// export const { } = meSlice.actions;

export const selectMeState = (state: AppState) => state.me;
export const selectMe = (state: AppState) => selectMeState(state).user;
export const selectDefaultRoute = (state: AppState) =>
  selectIsLoggedIn(state) ? AppConfig.app.loggedInDefaultRoute : AppConfig.app.defaultRoute;
export const selectCurrentCompanySlug = (state: AppState) => selectMeState(state).currentCompanySlug ?? '';
export const selectIsLoggedIn = (state: AppState) => selectMeState(state).isLoggedIn;
export const selectMeRoles = (state: AppState) => selectMeState(state).roles;
export const selectMeAddresses = (state: AppState) => selectMeState(state).addresses;
export const selectMeCompanies = (state: AppState) => selectMeState(state).companies;

export default meSlice.reducer;
