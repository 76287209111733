import { InterfaceUser } from '@interfaces/InterfaceUser.ts';
import { Avatar, Button, Paper, Text, Title } from '@mantine/core';
interface Props {
  user: InterfaceUser;
}
const UserProfile = ({ user }: Props) => {
  return (
    <Paper p="md" shadow="xs">
      <Avatar src={user.imgUrl} size={100} />
      <Title order={2}>
        {user.firstname} {user.lastname}
      </Title>
      <Text>{user.email}</Text>
      <Text>Member since: {new Date(user.createdAt).toLocaleDateString()}</Text>
      <Text>Phone Numbers: {user.phoneNumbers.join(', ')}</Text>
      <Button color="blue" size="sm">
        Edit Profile
      </Button>
    </Paper>
  );
};

export default UserProfile;
