import { DispatchActionType } from '@/types/DispatchActionType.ts';
import { axiosClientNoCache, csrf } from '@lib/axiosClientCached.ts';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { selectCurrentCompanySlug } from '@store/reducers/meReducer.ts';
import { addErrorResponseNotification } from '@store/reducers/notificationReducer.ts';
import {
  InterfaceFunctionReplace,
  InterfaceVarReplace,
  replaceUrlState,
  replaceUrlVariable,
} from '@store/reducers/reducerHelpers/thunkHelperShared.ts';

export interface InterfaceThunkPostData<T = undefined, U = undefined | InterfaceVarReplace> {
  form?: T;
  urlVarsReplaceMap?: U;
}

const genericTypeThunkPostReplace = <T = undefined, U = undefined | InterfaceVarReplace>(
  type: string,
  url: string,
  replaceMap: InterfaceFunctionReplace = {},
  successDispatchArray: DispatchActionType[] = [],
  errorDispatchArray: DispatchActionType[] = [],
) => {
  return createAsyncThunk(
    `${type}/post/${url}`,
    async (data: InterfaceThunkPostData<T, U> | undefined, { rejectWithValue, dispatch, getState }) => {
      let fullUrl = replaceUrlState(url, replaceMap, getState);
      if (data?.urlVarsReplaceMap !== undefined) {
        fullUrl = replaceUrlVariable(fullUrl, data.urlVarsReplaceMap ?? {});
      }
      await csrf();
      const res = await axiosClientNoCache.post(fullUrl, data?.form).catch((error) => {
        dispatch(addErrorResponseNotification({ error }));
        errorDispatchArray.forEach((dispatchAction) => dispatch(dispatchAction));
        throw rejectWithValue({
          error: error.response.data.errors,
        });
      });
      successDispatchArray.forEach((dispatchAction) => dispatch(dispatchAction));
      return res.data.data;
    },
  );
};
const genericTypeThunkPost = <T = undefined, U = undefined | InterfaceVarReplace>(
  type: string,
  url: string,
  successDispatchArray: DispatchActionType[] = [],
  errorDispatchArray: DispatchActionType[] = [],
) => {
  return genericTypeThunkPostReplace<T, U>(type, url, {}, successDispatchArray, errorDispatchArray);
};

const genericTypeThunkPostCompany = <T = undefined, U = undefined | InterfaceVarReplace>(
  type: string,
  url: string,
  successDispatchArray: DispatchActionType[] = [],
  errorDispatchArray: DispatchActionType[] = [],
) => {
  return genericTypeThunkPostReplace<T, U>(
    type,
    url,
    {
      '{companySlug}': selectCurrentCompanySlug,
    },
    successDispatchArray,
    errorDispatchArray,
  );
};

export const thunkPost = {
  replUrl: genericTypeThunkPostReplace,
  compUrl: genericTypeThunkPostCompany,
  post: genericTypeThunkPost,
};

export default thunkPost;
