import bidRatesReducer from '@/store/reducers/bidRatesReducer';
import calendarReducer from '@/store/reducers/calendarReducer';
import deliveryReducer from '@/store/reducers/deliveryReducer';
import mapReducer from '@/store/reducers/mapReducer';
import meReducer from '@/store/reducers/meReducer';
import userReducer from '@/store/reducers/usersReducer';
import vehicleReducer, { loadInitialVehicleData } from '@/store/reducers/vehicleReducer';
import { Action, ThunkAction, configureStore, createSelector } from '@reduxjs/toolkit';
import { loadInitialDeliveryData } from '@store/reducers/deliveryReducer.ts';
import documentReducer, { loadInitialDocumentData } from '@store/reducers/documentReducer.ts';
import driverReducer, { loadInitialDriverData } from '@store/reducers/driverReducer.ts';
import notificationReducer from '@store/reducers/notificationReducer.ts';
import originatorReducer, { loadInitialOriginatorData } from '@store/reducers/originatorReducer.ts';
import orkestroReducer, { loadInitialOrkestroData } from '@store/reducers/orkestroReducer.ts';
import routeReducer from '@store/reducers/routeReducer.ts';
import themeConfigReducer from '@store/reducers/themeConfigReducer.tsx';
import isEqual from 'lodash-es/isEqual';
import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import watch from 'redux-watch';

import {
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector,
  useStore,
  type TypedUseSelectorHook,
} from 'react-redux';
import { persistReducer, persistStore } from 'redux-persist';

import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from 'redux-persist/es/constants';

const documentPersistConfig = {
  key: 'document',
  version: 1,
  storage,
  blacklist: [''],
};

const deliveryPersistConfig = {
  key: 'delivery',
  version: 1,
  storage,
  blacklist: [''],
};

const driverPersistConfig = {
  key: 'driver',
  version: 1,
  storage,
  blacklist: [''],
};

const mapPersistConfig = {
  key: 'map',
  version: 1,
  storage,
  blacklist: [''],
};

const calendarPersistConfig = {
  key: 'calendar',
  version: 1,
  storage,
  blacklist: [''],
};

const bidRatesPersistConfig = {
  key: 'bidRates',
  version: 1,
  storage,
  blacklist: [''],
};

const mePersistConfig = {
  key: 'me',
  version: 1,
  storage,
  blacklist: ['search', 'loading', 'hasErrors', 'error', 'errors'],
};

const notificationPersistConfig = {
  key: 'notification',
  version: 1,
  storage,
  blacklist: [''],
};

const originatorPersistConfig = {
  key: 'originator',
  version: 1,
  storage,
  blacklist: [''],
};

const orkestroPersistConfig = {
  key: 'orkestro',
  version: 1,
  storage,
  blacklist: [''],
};

const routePersistConfig = {
  key: 'route',
  version: 1,
  storage,
  blacklist: ['previousRoute', 'currentRoute'],
};

const themePersistConfig = {
  key: 'themeConfig',
  version: 1,
  storage,
  blacklist: [''],
};

const userPersistConfig = {
  key: 'user',
  version: 1,
  storage,
  blacklist: ['search', 'loading', 'hasErrors'],
};

const vehiclePersistConfig = {
  key: 'vehicle',
  version: 1,
  storage,
  blacklist: ['vehicleStatusTypeColourMap'],
};

const rootPersistConfig = {
  key: 'root',
  version: 1,
  storage,
  blacklist: [
    'map',
    'delivery',
    'document',
    'driver',
    'calendar',
    'me',
    'notification',
    'originator',
    'orkestro',
    'route',
    'themeConfig',
    'user',
    'vehicle',
  ],
};

const reducers = combineReducers({
  delivery: persistReducer(deliveryPersistConfig, deliveryReducer),
  document: persistReducer(documentPersistConfig, documentReducer),
  driver: persistReducer(driverPersistConfig, driverReducer),
  map: persistReducer(mapPersistConfig, mapReducer),
  calendar: persistReducer(calendarPersistConfig, calendarReducer),
  bidRates: persistReducer(bidRatesPersistConfig, bidRatesReducer),
  me: persistReducer(mePersistConfig, meReducer),
  notification: persistReducer(notificationPersistConfig, notificationReducer),
  originator: persistReducer(originatorPersistConfig, originatorReducer),
  orkestro: persistReducer(orkestroPersistConfig, orkestroReducer),
  route: persistReducer(routePersistConfig, routeReducer),
  themeConfig: persistReducer(themePersistConfig, themeConfigReducer),
  user: persistReducer(userPersistConfig, userReducer),
  vehicle: persistReducer(vehiclePersistConfig, vehicleReducer),
});

const persistedReducer = persistReducer(rootPersistConfig, reducers);

const appStore = configureStore({
  reducer: persistedReducer,
  // reducer: reducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
  // .concat(logger),
  devTools: !import.meta.env.PROD,
});
export const appPersistStore = persistStore(appStore);

const w = watch(appStore.getState, 'me.user', isEqual);
appStore.subscribe(
  w(async (newVal: any) => {
    if (newVal === null) {
      console.log('Log out');
      await persistStore(appStore).purge();
    }
  }),
);

export type AppStore = typeof appStore;
export type AppState = ReturnType<AppStore['getState']>;
export type AppDispatch = AppStore['dispatch'];

export const useAppDispatch = () => useReduxDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<AppState> = useReduxSelector;

export const createAppSelector = createSelector.withTypes<AppState>();

export const useAppStore: () => AppStore = useStore;

// setupListeners(reduxStore.dispatch);

export type ReduxThunkAction<ReturnType = void> = ThunkAction<ReturnType, AppState, unknown, Action>;

export default appStore;

appStore.dispatch(loadInitialDeliveryData());
appStore.dispatch(loadInitialDocumentData());
appStore.dispatch(loadInitialDriverData());
appStore.dispatch(loadInitialOriginatorData());
appStore.dispatch(loadInitialOrkestroData());
appStore.dispatch(loadInitialVehicleData());

// appStore.dispatch(loadInitialMeData());
