import UserProfile from '@pages/User/UserProfile.tsx';
import { useAppSelector } from '@store/appStore.ts';
import { selectMe } from '@store/reducers/meReducer.ts';

const MeProfile = () => {
  const me = useAppSelector(selectMe);
  return me !== null ? <UserProfile user={me} /> : <></>;
};

export default MeProfile;
