import { AsyncThunk, AsyncThunkAction, PayloadAction } from '@reduxjs/toolkit';
import Axios from 'axios';
import { setupCache } from 'axios-cache-interceptor';
import { NavigateFunction } from 'react-router';

export const baseURL = import.meta.env.VITE_BACKEND_URL || '/api/v1';

export const axiosClientNoCache = Axios.create({
  baseURL,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
  },
  withCredentials: true,
});

const axiosClientCached = setupCache(axiosClientNoCache);

export const csrf = async () => {
  await axiosClientCached.get('/sanctum/csrf-cookie');
};

export const routeOnFulfilled = async (
  navigate: NavigateFunction,
  route: string,
  resultAction: AsyncThunkAction<any, any, any> | PayloadAction<any>,
  asyncThunk: AsyncThunk<any, any, any>,
) => {
  if (asyncThunk.fulfilled.match(resultAction)) {
    navigate(route);
  }
};

export const checkIfActionFulfilled = async (
  resultAction: AsyncThunkAction<any, any, any> | PayloadAction<any>,
  asyncThunk: AsyncThunk<any, any, any>,
) => {
  return asyncThunk.fulfilled.match(resultAction);
};

export const checkIfActionRejected = async (
  resultAction: AsyncThunkAction<any, any, any> | PayloadAction<any>,
  asyncThunk: AsyncThunk<any, any, any>,
) => {
  return asyncThunk.rejected.match(resultAction);
};

export default axiosClientCached;
