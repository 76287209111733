import { InterfaceUser } from '@interfaces/InterfaceUser.ts';
import { createSlice } from '@reduxjs/toolkit';
import { AppState } from '@store/appStore.ts';
import { meLogOut } from '@store/reducers/meReducer.ts';
import { sliceStateCheckAndUpdate } from '@store/reducers/reducerHelpers/sliceStateCheckAndUpdate.ts';
import { thunkGet } from '@store/reducers/reducerHelpers/thunkGet.ts';
import { thunkPost } from '@store/reducers/reducerHelpers/thunkPost.ts';
import memoize from 'lodash-es/memoize';

export interface UserState {
  users: { [uuid: string]: InterfaceUser };
  adminUuids: string[];
}

const initialState: UserState = {
  users: {},
  adminUuids: [],
};

const sliceName = 'user';
export const usersSlice = createSlice({
  name: sliceName,
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(meLogOut.fulfilled, () => initialState)
      .addCase(getCompanyUsers.fulfilled, sliceStateCheckAndUpdate.sliceItem('users'))
      .addCase(getCompanyAdminUserUuid.fulfilled, sliceStateCheckAndUpdate.sliceItem('adminUuids'))
      .addCase(updateUserStatus.fulfilled, sliceStateCheckAndUpdate.sliceItemElementGeneric('users', 'uuid'));
  },
});

export const getCompanyUsers = thunkGet.compUrl(sliceName, '/c/{companySlug}/admin/user');
export const getCompanyAdminUserUuid = thunkGet.compUrl(sliceName, '/c/{companySlug}/admin/user/admin/uuid');

export interface InterfaceJoinBetaProgramForm {
  name: string;
  phoneNumber: string;
  email: string;
  random_things: string;
  message: string;
}

export const joinBetaProgram = thunkPost.post<InterfaceJoinBetaProgramForm>(sliceName, `/email/join`);
export const getUsers = thunkGet.compUrl(sliceName, `c/{companySlug}/admin/user`);

export interface InterfaceUpdateUserStatusForm {
  userId: string;
  status: string;
}

export const updateUserStatus = thunkPost.compUrl<InterfaceUpdateUserStatusForm, { '{userId}': string }>(
  sliceName,
  `c/{companySlug}/admin/user/{userId}/status`,
);

export const selectDriverName = memoize((state: AppState, driverUuid: string) => {
  const user = selectUserState(state).users[driverUuid];
  return user ? `${user.firstname} ${user.lastname}` : null;
});

// Action creators are generated for each case reducer function
// export const { setUsers, setUser, setSearch } = usersSlice.actions;

export const selectUserState = (state: AppState) => state.user;
export const selectUsers = memoize((state: AppState) => selectUserState(state).users);

export default usersSlice.reducer;
