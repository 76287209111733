import { LocalCodeType } from '@config/LanguageListConfig.ts';
import { createSlice } from '@reduxjs/toolkit';
import { AppState } from '@store/appStore.ts';

interface ThemeConfigStateInterface {
  animation: string;
  isDarkMode: boolean;
  layout: string;
  locale: LocalCodeType;
  mainLayout: string;
  menu: string;
  navbar: string;
  pageTitle: string;
  isSemiDark: boolean;
  sidebar: boolean;
  theme: string;
}

export const initialStateThemeConfig: ThemeConfigStateInterface = {
  animation: '',
  isDarkMode: false,
  layout: 'full',
  locale: 'en',
  mainLayout: 'app',
  menu: 'vertical',
  navbar: 'navbar-sticky',
  pageTitle: '',
  isSemiDark: false,
  sidebar: false,
  theme: 'light',
};

const themeConfigReducer = createSlice({
  name: 'themeConfig',
  initialState: initialStateThemeConfig,
  reducers: {
    toggleTheme(state, { payload }) {
      state.theme = String(payload);
      if (state.theme === 'light') {
        state.isDarkMode = false;
      } else if (state.theme === 'dark') {
        state.isDarkMode = true;
      } else if (payload === 'system') {
        state.isDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
      }

      if (state.isDarkMode) {
        document.querySelector('body')?.classList.add('dark');
      } else {
        document.querySelector('body')?.classList.remove('dark');
      }
    },
    toggleMenu(state, { payload }) {
      payload = payload || state.menu; // vertical, collapsible-vertical, horizontal
      state.sidebar = false; // reset sidebar state
      state.menu = payload;
    },
    toggleLayout(state, { payload }) {
      payload = payload || state.layout; // full, boxed-layout
      state.layout = payload;
    },
    toggleAnimation(state, { payload }) {
      payload = payload || state.animation; // animate__fadeIn, animate__fadeInDown, animate__fadeInUp, animate__fadeInLeft, animate__fadeInRight, animate__slideInDown, animate__slideInLeft, animate__slideInRight, animate__zoomIn
      payload = payload?.trim();
      state.animation = payload;
    },
    toggleNavbar(state, { payload }) {
      payload = payload || state.navbar; // navbar-sticky, navbar-floating, navbar-static
      state.navbar = payload;
    },
    toggleSemidark(state, { payload }) {
      state.isSemiDark = payload;
    },
    toggleSidebar(state) {
      state.sidebar = !state.sidebar;
    },
    setPageTitle(state, { payload }) {
      state.pageTitle = payload;
      document.title = `${payload}`;
    },
  },
});

export const {
  toggleTheme,
  toggleMenu,
  toggleLayout,
  toggleAnimation,
  toggleNavbar,
  toggleSemidark,
  toggleSidebar,
  setPageTitle,
} = themeConfigReducer.actions;

export const selectAppTheme = (state: AppState) => state.themeConfig;

export const selectAppThemeName = (state: AppState) => selectAppTheme(state).theme;
export const selectAppThemeIsDark = (state: AppState) =>
  selectAppThemeName(state) === 'dark' || selectAppTheme(state).isDarkMode;

export const selectAppThemeIsSemiDark = (state: AppState) => selectAppTheme(state).isSemiDark;

export default themeConfigReducer.reducer;
