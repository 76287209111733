import { createSlice } from '@reduxjs/toolkit';
import { AppState } from '@store/appStore.ts';
import { Location } from 'history';
import isEqual from 'lodash-es/isEqual';
import { Params } from 'react-router-dom';

export interface InterfaceRoute {
  location: Location;
  params: Params;
  searchParams: { [p: string]: string };
}

export interface RouteReducerState {
  previousRoute: InterfaceRoute | null;
  currentRoute: InterfaceRoute | null;
}

const initialState: RouteReducerState = {
  previousRoute: null,
  currentRoute: null,
};

export const routeSlice = createSlice({
  name: 'route',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setRoute: (state, action) => {
      if (!isEqual(state.currentRoute, action.payload)) {
        state.previousRoute = state.currentRoute;
        state.currentRoute = action.payload;
      }
    },
  },
});

export const { setRoute } = routeSlice.actions;

export const selectRouteState = (state: AppState) => state.route;
export const selectCurrentRoute = (state: AppState) => selectRouteState(state).currentRoute;
export const selectPreviousRoute = (state: AppState) => selectRouteState(state).previousRoute;

export default routeSlice.reducer;
