import { Notifications } from '@mantine/notifications';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';

// Router
import Router from '@router/Router.tsx';
import { RouterProvider } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

// Redux
import AppConfig from '@config/AppConfig.ts';
import { MantineProvider } from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';
import appStore, { appPersistStore } from '@store/appStore.ts';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';

// css globals
import '@/styles/global.css';

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <HelmetProvider>
    <React.StrictMode>
      <Suspense>
        <Provider store={appStore}>
          <PersistGate loading={null} persistor={appPersistStore}>
            <MantineProvider
              defaultColorScheme={AppConfig.mantine.defaultColorScheme}
              theme={AppConfig.mantine.mantineThemeOverride}>
              <ModalsProvider>
                <Notifications />
                <RouterProvider router={Router} />
              </ModalsProvider>
            </MantineProvider>
          </PersistGate>
        </Provider>
      </Suspense>
    </React.StrictMode>
  </HelmetProvider>,
);
