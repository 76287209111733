import { CalendarEvent } from '@interfaces/InterfaceCalendarEvent.ts';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { meLogOut } from '@store/reducers/meReducer.ts';

export interface CalendarState {
  events: CalendarEvent[];
}

const initialState: CalendarState = {
  events: [
    {
      id: '14',
      title: 'Today',
      start: new Date(new Date().setHours(new Date().getHours() - 3)).toISOString(),
      end: new Date(new Date().setHours(new Date().getHours() + 3)).toISOString(),
    },
    {
      id: '1',
      title: 'Delivery 2',
      start: new Date(2024, 1, 14).toISOString(), // February 14, 2024
      end: new Date(2024, 1, 15).toISOString(), // February 15, 2024
      allDay: false,
    },
    {
      id: '2',
      title: 'Delivery 1',
      start: new Date(2024, 1, 2, 10, 0).toISOString(),
      end: new Date(2024, 1, 2, 11, 0).toISOString(),
      allDay: false,
    },
  ],
};

export const sliceName = 'calendar';

export const calendarSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    addEvent: (state, action: PayloadAction<CalendarEvent>) => {
      state.events.push(action.payload);
    },
    updateEvent: (state, action: PayloadAction<CalendarEvent>) => {
      const index = state.events.findIndex((event) => event.id === action.payload.id);
      if (index !== -1) {
        state.events[index] = action.payload;
      }
    },
    removeEvent: (state, action: PayloadAction<string>) => {
      const index = state.events.findIndex((event) => event.id === action.payload);
      if (index !== -1) {
        state.events.splice(index, 1);
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(meLogOut.fulfilled, () => initialState);
  },
});

export const { addEvent, updateEvent, removeEvent } = calendarSlice.actions;

export default calendarSlice.reducer;
