import { InterfaceGenericMap } from '@interfaces/InterfaceGenericMap.ts';
import { InterfaceGenericTypeSlug } from '@interfaces/InterfaceGenericTypeSlug.ts';
import { createSlice } from '@reduxjs/toolkit';
import { AppState } from '@store/appStore.ts';
import { meLogOut } from '@store/reducers/meReducer.ts';
import createDebouncedAsyncThunk from '@store/reducers/reducerHelpers/createDebouncedAsyncThunk.ts';
import { sliceStateCheckAndUpdate } from '@store/reducers/reducerHelpers/sliceStateCheckAndUpdate.ts';
import { thunkGet } from '@store/reducers/reducerHelpers/thunkGet.ts';
import { defaultTypeDebounceTimeMs } from '@store/reducers/reducerHelpers/thunkHelperShared.ts';

export interface OrkestroReducerState {
  orkestroActionTypes: InterfaceGenericMap<InterfaceGenericTypeSlug>;
  orkestroDeliveryLiveStatusTypes: InterfaceGenericMap<InterfaceGenericTypeSlug>;
  orkestroDeliveryLiveStatusPayloadTypes: InterfaceGenericMap<InterfaceGenericTypeSlug>;
  orkestroRequestStatusTypes: InterfaceGenericMap<InterfaceGenericTypeSlug>;
  orkestroScheduleTypes: InterfaceGenericMap<InterfaceGenericTypeSlug>;
  orkestroServiceTypes: InterfaceGenericMap<InterfaceGenericTypeSlug>;
  orkestroTransportTypes: InterfaceGenericMap<InterfaceGenericTypeSlug>;
}

const initialState: OrkestroReducerState = {
  orkestroActionTypes: {},
  orkestroDeliveryLiveStatusTypes: {},
  orkestroDeliveryLiveStatusPayloadTypes: {},
  orkestroRequestStatusTypes: {},
  orkestroScheduleTypes: {},
  orkestroServiceTypes: {},
  orkestroTransportTypes: {},
};

const sliceName = 'orkestro';

export const orkestroSlice = createSlice({
  name: sliceName,
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(meLogOut.fulfilled, () => initialState)
      .addCase(getOrkestroActionTypes.fulfilled, sliceStateCheckAndUpdate.sliceItem('orkestroActionTypes'))
      .addCase(
        getOrkestroDeliveryLiveStatusTypes.fulfilled,
        sliceStateCheckAndUpdate.sliceItem('orkestroDeliveryLiveStatusTypes'),
      )
      .addCase(
        getOrkestroDeliveryLiveStatusPayloadTypes.fulfilled,
        sliceStateCheckAndUpdate.sliceItem('orkestroDeliveryLiveStatusPayloadTypes'),
      )
      .addCase(
        getOrkestroRequestStatusTypes.fulfilled,
        sliceStateCheckAndUpdate.sliceItem('orkestroRequestStatusTypes'),
      )
      .addCase(getOrkestroScheduleTypes.fulfilled, sliceStateCheckAndUpdate.sliceItem('orkestroScheduleTypes'))
      .addCase(getOrkestroServiceTypes.fulfilled, sliceStateCheckAndUpdate.sliceItem('orkestroServiceTypes'))
      .addCase(getOrkestroTransportTypes.fulfilled, sliceStateCheckAndUpdate.sliceItem('orkestroTransportTypes'));
  },
});

export const getOrkestroActionTypes = thunkGet.get(sliceName, 'type/orkestro/action');
export const getOrkestroDeliveryLiveStatusTypes = thunkGet.get(sliceName, 'type/orkestro/delivery/live/status');
export const getOrkestroDeliveryLiveStatusPayloadTypes = thunkGet.get(
  sliceName,
  'type/orkestro/delivery/live/status/payload',
);
export const getOrkestroRequestStatusTypes = thunkGet.get(sliceName, 'type/orkestro/request/status');
export const getOrkestroScheduleTypes = thunkGet.get(sliceName, 'type/orkestro/schedule');
export const getOrkestroServiceTypes = thunkGet.get(sliceName, 'type/orkestro/service');
export const getOrkestroTransportTypes = thunkGet.get(sliceName, 'type/orkestro/transport');

// export const {} = orkestroSlice.actions;

export const selectOrkestroState = (state: AppState) => state.orkestro;
export const selectOrkestroActionTypes = (state: AppState) => selectOrkestroState(state).orkestroActionTypes;

export default orkestroSlice.reducer;

// Only for initial load of data that doesn't require authentication
// This is only done to speed things up. It is not required.
// This data should be reloaded in the pages that need it.
// Load any data a page needs in the page. Else it will not update ever.
export const loadInitialOrkestroData = createDebouncedAsyncThunk(
  `${sliceName}/initial`,
  async (_, { dispatch }) => {
    dispatch(getOrkestroActionTypes());
    dispatch(getOrkestroDeliveryLiveStatusTypes());
    dispatch(getOrkestroDeliveryLiveStatusPayloadTypes());
    dispatch(getOrkestroRequestStatusTypes());
    dispatch(getOrkestroScheduleTypes());
    dispatch(getOrkestroServiceTypes());
    dispatch(getOrkestroTransportTypes());
  },
  defaultTypeDebounceTimeMs,
);
