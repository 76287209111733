import { HandleErrorResponseNotificationParams } from '@interfaces/HandleErrorResponseNotificationParams.ts';
import { InterfaceGenericMap } from '@interfaces/InterfaceGenericMap.ts';
import { InterfaceNotification } from '@interfaces/InterfaceNotification.ts';
import { InterfaceNotificationMsg } from '@interfaces/InterfaceNotificationMsg.ts';
import { InterfaceNotificationStandard } from '@interfaces/InterfaceNotificationStandard.ts';
import { InterfaceNotificationStyle } from '@interfaces/InterfaceNotificationStyle.ts';
import TTLCache from '@isaacs/ttlcache';
import { notifications as notify } from '@mantine/notifications';
import { createSlice } from '@reduxjs/toolkit';
import { meLogOut } from '@store/reducers/meReducer.ts';
import { AxiosError } from 'axios';

const notificationCacheTime = 2000; //2 seconds
export const notificationCache = new TTLCache({ max: 100, ttl: notificationCacheTime });

const notificationStyleDefault = {
  withBorder: true,
  withCloseButton: true,
  autoClose: 5000,
};

const notificationStyles: InterfaceGenericMap<InterfaceNotificationStyle> = {
  success: {
    colour: 'teal',
  },
  error: {
    colour: 'red',
  },
  warning: {
    colour: 'orange',
  },
  info: {
    colour: 'indigo',
  },
};

export interface NotificationState {
  notifications: InterfaceGenericMap<InterfaceNotification>;
}

const initialState: NotificationState = {
  notifications: {},
};
export const notificationSlice = createSlice({
  name: 'notification',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    addGenericNotification: (_, action) => {
      showNotification(action.payload);
      // state.notifications = { ...state.notifications, ...{ [n.id]: n } };
    },
    removeNotification: (state, action) => {
      const n: InterfaceNotification = action.payload;
      if (n === undefined || n.id === undefined || state.notifications[n.id] === undefined) return;
      delete state.notifications[n.id];
    },
    removeNotificationById: (state, action) => {
      const nId: string = action.payload;
      if (nId === undefined || state.notifications[nId] === undefined) return;
      delete state.notifications[nId];
    },
    addStandardNotification: (_, action) => {
      sendStandardNotification(action.payload);
    },
    addSuccessNotification: (_, action) => {
      const msg: InterfaceNotificationMsg = action.payload;
      sendSuccessNotification(msg);
    },
    addErrorNotification: (_, action) => {
      sendErrorNotification(action.payload);
    },
    addErrorResponseNotification: (_, action) => {
      handleErrorResponseNotification(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(meLogOut.fulfilled, () => initialState);
  },
});

const notInCache = (n: InterfaceNotification): boolean => {
  const key = n.type + n.title + n.message;
  if (notificationCache.has(key)) {
    return false;
  }
  notificationCache.set(key, true);
  return true;
};

const showNotification = (n: InterfaceNotification): InterfaceNotification => {
  n.style = {
    ...notificationStyleDefault,
    ...notificationStyles[n.type],
    ...(n.style ?? {}),
  };
  n.createdAt = new Date();

  const nConfig = {
    id: n?.id ?? undefined,
    title: n.title,
    message: n.message,
    autoClose: n.style.autoClose ?? undefined,
    color: n.style.colour ?? undefined,
    withBorder: n.style.withBorder ?? undefined,
    style: n.style.style ?? undefined,
    radius: n.style?.radius ?? undefined,
    icon: n.style.icon ?? undefined,
    className: n.style.className ?? undefined,
    loading: n.style.loading ?? undefined,
    onClose: n.style?.onClose ?? undefined,
    onOpen: n.style?.onOpen ?? undefined,
    withCloseButton: n.style?.withCloseButton ?? undefined,
    closeButtonProps: n.style.closeButtonProps ?? undefined,
  };

  if (notInCache(n)) {
    n.id = notify.show(nConfig);
    delete n.style.onClose;
    delete n.style.onOpen;
    delete n.style.withCloseButton;
    delete n.style.closeButtonProps;
  }
  return n;
  // state.notifications = { ...state.notifications, ...{ [n.id]: n } };
};

function sendStandardNotification({ type, msg }: InterfaceNotificationStandard): InterfaceNotification {
  const n: InterfaceNotification = {
    title: msg.title,
    message: msg.message,
    type,
  };
  return showNotification(n);
}

function sendSuccessNotification(msg: InterfaceNotificationMsg) {
  return sendStandardNotification({
    type: 'success',
    msg,
  });
}

function sendErrorNotification(msg: InterfaceNotificationMsg) {
  return sendStandardNotification({
    type: 'error',
    msg,
  });
}

export function handleErrorResponseNotification({ error }: HandleErrorResponseNotificationParams) {
  console.log('error', error);
  let message = 'Unknown Error';
  let code = 500;

  if (error instanceof AxiosError) {
    message = error.response?.data.message || 'Server Unavailable';
    code = error.response?.status || 503;
  } else if (error instanceof Error) {
    message = error.message;
  } else if (error !== undefined) {
    message = error;
  }
  sendErrorNotification({
    title: `Error ${code}`,
    message,
  });
  return {
    code,
    error: message,
  };
}

export const {
  addGenericNotification,
  removeNotification,
  removeNotificationById,
  addStandardNotification,
  addSuccessNotification,
  addErrorNotification,
  addErrorResponseNotification,
} = notificationSlice.actions;

export default notificationSlice.reducer;
