import { InterfaceDocumentTypes } from '@interfaces/InterfaceDocumentTypes.ts';
import { InterfaceGenericMap } from '@interfaces/InterfaceGenericMap.ts';
import { InterfaceGenericTypeSlug } from '@interfaces/InterfaceGenericTypeSlug.ts';
import { createSlice } from '@reduxjs/toolkit';
import { AppState } from '@store/appStore.ts';
import { meLogOut } from '@store/reducers/meReducer.ts';
import createDebouncedAsyncThunk from '@store/reducers/reducerHelpers/createDebouncedAsyncThunk.ts';
import { sliceStateCheckAndUpdate } from '@store/reducers/reducerHelpers/sliceStateCheckAndUpdate.ts';
import { thunkGet } from '@store/reducers/reducerHelpers/thunkGet.ts';
import { defaultTypeDebounceTimeMs } from '@store/reducers/reducerHelpers/thunkHelperShared.ts';

export interface DocumentReducerState {
  documentTypes: InterfaceGenericMap<InterfaceDocumentTypes>;
  documentFormatTypes: InterfaceGenericMap<InterfaceGenericTypeSlug>;
  documentSectionsTypes: InterfaceGenericMap<InterfaceGenericTypeSlug>;
}

const initialState: DocumentReducerState = {
  documentTypes: {},
  documentFormatTypes: {},
  documentSectionsTypes: {},
};

export const sliceName = 'document';

export const documentSlice = createSlice({
  name: 'document',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(meLogOut.fulfilled, () => initialState)
      .addCase(getDocumentTypes.fulfilled, sliceStateCheckAndUpdate.sliceItem('documentTypes'))
      .addCase(getDocumentFormatTypes.fulfilled, sliceStateCheckAndUpdate.sliceItem('documentFormatTypes'))
      .addCase(getDocumentSectionsTypes.fulfilled, sliceStateCheckAndUpdate.sliceItem('documentSectionsTypes'));
  },
});

export const getDocumentTypes = thunkGet.get(sliceName, 'type/document');
export const getDocumentFormatTypes = thunkGet.get(sliceName, 'type/document/format');
export const getDocumentSectionsTypes = thunkGet.get(sliceName, 'type/document/section');

// export const {} = documentSlice.actions;

export const selectDocumentState = (state: AppState) => state.document;
export const selectDocumentTypes = (state: AppState) => selectDocumentState(state).documentTypes;

export default documentSlice.reducer;

// Only for initial load of data that doesn't require authentication
// This is only done to speed things up. It is not required.
// This data should be reloaded in the pages that need it.
// Load any data a page needs in the page. Else it will not update ever.
export const loadInitialDocumentData = createDebouncedAsyncThunk(
  `${sliceName}/initial`,
  async (_, { dispatch }) => {
    dispatch(getDocumentTypes());
    dispatch(getDocumentFormatTypes());
    dispatch(getDocumentSectionsTypes());
  },
  defaultTypeDebounceTimeMs,
);
