import axiosClientCached from '@lib/axiosClientCached.ts';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppState } from '@store/appStore.ts';
import { selectIsLoggedIn } from '@store/reducers/meReducer.ts';
import dayjs from 'dayjs';

export let extraLastChecked = dayjs().subtract(1, 'hour').toISOString();

// Ignore this one as its odd an unlikely a pattern you will ever need to use!!!
export const meCheckIfLoggedIn = createAsyncThunk('me/check', async (_, { rejectWithValue, getState }) => {
  if (dayjs().diff(extraLastChecked, 'seconds') < 5) {
    const isLoggedIn = selectIsLoggedIn(getState() as AppState);
    if (!isLoggedIn) {
      throw rejectWithValue({
        error: {
          statusCode: 401,
          message: 'Unauthorized',
        },
      });
    }
    return true;
  }
  extraLastChecked = dayjs().toISOString();
  const res = await axiosClientCached.get('/me/check').catch((e) => {
    const error = {
      statusCode: e.response.status,
      data: e.response.data,
    };
    throw rejectWithValue({ error });
  });
  return res.data;
});
