/*vehicle images TODO: check with Gavin and Tim on sizes and add foot, bicycle etc*/
import vanLargeImg from '@assets/images/vehicle_example_images/18tonne_luton.webp';
import vanSmallImg from '@assets/images/vehicle_example_images/3-5tonne_luton.webp';
import vanMediumImg from '@assets/images/vehicle_example_images/5-5tonne_luton.webp';
import carCompactImg from '@assets/images/vehicle_example_images/car_compact.webp';
import carEstateImg from '@assets/images/vehicle_example_images/car_estate.webp';
import carSedanImg from '@assets/images/vehicle_example_images/car_sedan.webp';
import carSuvImg from '@assets/images/vehicle_example_images/car_suv.webp';
import electricBikeImg from '@assets/images/vehicle_example_images/electric_bike.webp';
import heavyTruckImg from '@assets/images/vehicle_example_images/heavy_truck.webp';
import unknownImg from '@assets/images/vehicle_example_images/replace_me.webp';
import scooterBigBoxImg from '@assets/images/vehicle_example_images/scooter_big_box.webp';
import semiTruckImg from '@assets/images/vehicle_example_images/semi_truck.webp';
import truckLightImg from '@assets/images/vehicle_example_images/truck_light.webp';
import { InterfaceGenericMap } from '@interfaces/InterfaceGenericMap.ts';

export const vehicleSizeImageUrls: InterfaceGenericMap<string> = {
  unknown: unknownImg,
  foot: electricBikeImg,
  bicycle: electricBikeImg,
  'bicycle-cargo': electricBikeImg,
  motorcycle: scooterBigBoxImg,
  'car-compact': carCompactImg,
  'car-sedan': carSedanImg,
  'car-suv': carSuvImg,
  'car-estate': carEstateImg,
  'van-small': vanSmallImg,
  'van-medium': vanMediumImg,
  'van-large': vanLargeImg,
  'van-extra-large': vanLargeImg,
  'truck-light': truckLightImg,
  'truck-medium': vanLargeImg,
  'truck-heavy': heavyTruckImg,
  'truck-semi': semiTruckImg,
};
