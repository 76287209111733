import AppConfig from '@config/AppConfig.ts';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppState } from '@store/appStore.ts';
import { meLogOut } from '@store/reducers/meReducer.ts';
import { Feature, FeatureCollection } from 'geojson';

export interface VehicleLocation {
  lng: number;
  lat: number;
  uuid: string;
}
export interface MapState {
  center: { lng: number; lat: number };
  zoom: number;
  geojson: FeatureCollection;
  vehicles: { [uuid: string]: VehicleLocation };
  destination: { lng: number; lat: number } | undefined;
  origin: { lng: number; lat: number } | undefined;
}

export function sanitizeLngLat(lng: number, lat: number): [number, number] {
  if (lng < -180 || lng > 180) {
    console.warn(`Invalid longitude value: ${lng}. Setting to default.`);
    lng = 0;
  }
  if (lat < -90 || lat > 90) {
    console.warn(`Invalid latitude value: ${lat}. Setting to default.`);
    lat = 0;
  }
  return [lng, lat];
}

const initialGeoJSON: FeatureCollection = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [AppConfig.map.mapDefaultCenter.lng, AppConfig.map.mapDefaultCenter.lat],
      },
      properties: {
        title: 'Mapbox',
        description: 'Location 1',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.3294, -25.75],
      },
      properties: {
        title: 'Mapbox',
        description: 'Location 2',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.3274, -25.74],
      },
      properties: {
        title: 'DID001',
        description: 'Urgent',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-25.948418098845078, 30.53789156015757],
      },
      properties: {
        title: 'DID002',
        description: 'Standard',
      },
    },
  ],
};

const initialState: MapState = {
  vehicles: {},
  center: { lng: 30.3294, lat: -25.7339 },
  zoom: 10,
  geojson: initialGeoJSON,
  destination: undefined,
  origin: undefined,
};

export const sliceName = 'map';

export const mapSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setCenter: (state, action: PayloadAction<{ lng: number; lat: number }>) => {
      state.center = action.payload;
    },
    setZoom: (state, action: PayloadAction<number>) => {
      state.zoom = action.payload;
    },
    setGeoJSON: (state, action: PayloadAction<FeatureCollection>) => {
      // New reducer
      state.geojson = action.payload;
    },
    addFeature: (state, action: PayloadAction<Feature>) => {
      // New action
      state.geojson.features.push(action.payload);
    },
    updateVehicles: (state, action: PayloadAction<{ [uuid: string]: VehicleLocation }>) => {
      state.vehicles = action.payload;
      state.geojson.features = Object.values(state.vehicles).map((vehicle) => {
        const [sanitizedLng, sanitizedLat] = sanitizeLngLat(vehicle.lng, vehicle.lat);
        return {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [sanitizedLng, sanitizedLat],
          },
          properties: {
            title: 'Vehicle',
            description: vehicle.uuid,
          },
        };
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(meLogOut.fulfilled, () => initialState);
  },
});

export const selectMapState = (state: AppState) => state.map;
export const selectMapGeoJsonState = (state: AppState) => selectMapState(state).geojson;
export const selectMapCenter = (state: AppState) => selectMapState(state).center;
export const selectMapZoom = (state: AppState) => selectMapState(state).zoom;
export const selectMapOrigin = (state: AppState) => selectMapState(state).origin;
export const selectMapDestination = (state: AppState) => selectMapState(state).destination;

export const { setCenter, setZoom, setGeoJSON, addFeature, updateVehicles } = mapSlice.actions;

export default mapSlice.reducer;
