import MeProfile from '@pages/User/MeProfile.tsx';
import { lazy } from 'react';

const BidDetail = lazy(() => import('@pages/Courier/BidDetail'));
const BiddingDashboard = lazy(() => import('@pages/Courier/BiddingDashboard'));
const BidsOpen = lazy(() => import('@/components/Bids/BidsOpen'));
const CourierDashboard = lazy(() => import('@pages/Courier/CourierDashboard'));
const DriverAdd = lazy(() => import('@pages/Courier/Drivers/DriverAdd.tsx'));
const DriverAddCompany = lazy(() => import('@pages/Courier/Drivers/DriverAddCompany.tsx'));
const AdminAddCompany = lazy(() => import('@pages/Courier/AdminAddCompany.tsx'));
const DriverDashboard = lazy(() => import('@pages/Driver/DriverDashboard'));
const DeliveryCalendar = lazy(() => import('@pages/Courier/DeliveryCalendar'));
const DeliveryDetail = lazy(() => import('@pages/Courier/DeliveryDetail'));
const DriverDeliveryDetail = lazy(() => import('@pages/Courier/Drivers/DriverDeliveryDetail'));
const DriversManage = lazy(() => import('@pages/Courier/Drivers/DriversManage.tsx'));
const FleetManagement = lazy(() => import('@pages/Courier/FleetManagement'));
const LandingPage = lazy(() => import('@pages/LandingPage'));
const LoginBoxed = lazy(() => import('@pages/Authentication/LoginBoxed'));
const NotFound404 = lazy(() => import('@pages/NotFound404.tsx'));
const Portal = lazy(() => import('@pages/Portal'));
const RecoverIdBoxed = lazy(() => import('@pages/Authentication/RecoverIdBox'));
const RegisterBoxed = lazy(() => import('@pages/Authentication/RegisterBoxed'));
const ShipperDashboard = lazy(() => import('@pages/Shipper/ShipperDashboard'));
const UnlockBoxed = lazy(() => import('@pages/Authentication/UnlockBox'));
const VehicleAdd = lazy(() => import('@pages/Courier/Vehicle/VehicleAdd.tsx'));
const VehicleManage = lazy(() => import('@pages/Courier/Vehicle/VehicleManage.tsx'));
const VehicleTypeAdd = lazy(() => import('@pages/Courier/Vehicle/VehicleTypeAdd.tsx'));
const UserProfile = lazy(() => import('@/pages/AccountPages/UserProfile'));
const UserAccountSettings = lazy(() => import('@/pages/AccountPages/UserAccountSettings'));
const CompanyProfile = lazy(() => import('@/pages/AccountPages/CompanyProfile'));

const RoutesArray = [
  // Login
  // {
  //   path: '/',
  //   element: <LandingPage />,
  //   layout: 'blank',
  // }, // Courier Dashboard
  {
    path: '/',
    element: <Portal />,
    layout: 'blank',
  }, // Sign Up Portal
  {
    path: '/CourierDashboard',
    element: <CourierDashboard />,
    layout: 'default',
  }, // Bidding Dashboard
  {
    path: '/BiddingDashboard',
    element: <BiddingDashboard />,
    layout: 'default',
  },
  {
    path: '/BidsOpen',
    element: <BidsOpen />,
    layout: 'default',
  },
  {
    path: '/DeliveryCalendar',
    element: <DeliveryCalendar />,
    layout: 'default',
  },
  {
    path: '/DeliveryDetail/:uuid',
    element: <DeliveryDetail />,
    layout: 'default',
  },
  {
    path: '/DriverDeliveryDetail/:deliveryUuid',
    element: <DriverDeliveryDetail />,
    layout: 'default',
  },
  {
    path: '/FleetManagement',
    element: <FleetManagement />,
    layout: 'default',
  },
  {
    path: '/VehicleAdd',
    element: <VehicleAdd />,
    layout: 'default',
  },
  {
    path: '/VehicleManage',
    element: <VehicleManage />,
    layout: 'default',
  },
  {
    path: '/VehicleTypeAdd',
    element: <VehicleTypeAdd />,
    layout: 'default',
  },
  {
    path: '/DriversManage',
    element: <DriversManage />,
    layout: 'default',
  }, // Add Drivers
  {
    path: '/DriverAdd',
    element: <DriverAdd />,
    layout: 'default',
  },
  {
    path: '/DriverAddCompany',
    element: <DriverAddCompany />,
    layout: 'default',
  },
  {
    path: '/AdminAddCompany',
    element: <AdminAddCompany />,
    layout: 'default',
  },
  {
    path: '/DriverDashboard',
    element: <DriverDashboard />,
    layout: 'default',
  },
  {
    path: '/BIdDetail',
    element: <BidDetail />,
    layout: 'default',
  },
  {
    path: '/LandingPage',
    element: <LandingPage />,
    layout: 'blank',
  },
  {
    path: '/ShipperDashboard',
    element: <ShipperDashboard />,
    layout: 'default',
  }, //Authentication
  {
    path: '/Users/Me',
    element: <MeProfile />,
    layout: 'default',
  }, //Authentication
  {
    path: '/login',
    element: <LoginBoxed />,
    layout: 'blank',
  },
  {
    path: '/auth/boxed-signin',
    element: <LoginBoxed />,
    layout: 'blank',
  },
  {
    path: '/Authentication/RegisterBoxed',
    element: <RegisterBoxed />,
    layout: 'blank',
  },
  {
    path: '/auth/boxed-lockscreen',
    element: <UnlockBoxed />,
    layout: 'blank',
  },
  {
    path: '/auth/boxed-password-reset',
    element: <RecoverIdBoxed />,
    layout: 'blank',
  },
  {
    path: '/AccountPages/UserProfile',
    element: <UserProfile />,
    layout: 'default',
  },
  {
    path: '/AccountPages/UserAccountSettings',
    element: <UserAccountSettings />,
    layout: 'default',
  },
  {
    path: '/AccountPages/CompanyProfile',
    element: <CompanyProfile />,
    layout: 'default',
  },
  {
    path: '/*',
    element: <NotFound404 />,
    layout: 'blank',
  },
];

export { RoutesArray };
