import { InterfaceGenericMap } from '@interfaces/InterfaceGenericMap.ts';
import { InterfaceGenericTypeSlug } from '@interfaces/InterfaceGenericTypeSlug.ts';
import { InterfaceOriginatorAccount } from '@interfaces/InterfaceOriginatorAccount.ts';
import { createSlice } from '@reduxjs/toolkit';
import { AppState } from '@store/appStore.ts';
import { meLogOut } from '@store/reducers/meReducer.ts';
import createDebouncedAsyncThunk from '@store/reducers/reducerHelpers/createDebouncedAsyncThunk.ts';
import { sliceStateCheckAndUpdate } from '@store/reducers/reducerHelpers/sliceStateCheckAndUpdate.ts';
import { thunkGet } from '@store/reducers/reducerHelpers/thunkGet.ts';
import { defaultTypeDebounceTimeMs } from '@store/reducers/reducerHelpers/thunkHelperShared.ts';

export interface OriginatorReducerState {
  originators: InterfaceGenericMap<InterfaceGenericTypeSlug>;
  originatorAccounts: InterfaceGenericMap<InterfaceOriginatorAccount>;
}

const initialState: OriginatorReducerState = {
  originators: {},
  originatorAccounts: {},
};

export const sliceName = 'originator';

export const jobsSlice = createSlice({
  name: sliceName,
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(meLogOut.fulfilled, () => initialState)
      .addCase(getOriginators.fulfilled, sliceStateCheckAndUpdate.sliceItem('originators'))
      .addCase(getOriginatorAccounts.fulfilled, sliceStateCheckAndUpdate.sliceItem('originatorAccounts'));
  },
});
export const getOriginators = thunkGet.get(sliceName, 'type/o');
export const getOriginatorAccounts = thunkGet.compUrl(sliceName, '/c/{companySlug}/admin/o/account');

export const selectJobState = (state: AppState) => state.originator;
export const selectOriginators = (state: AppState) => selectJobState(state).originators;
export const selectOriginatorAccounts = (state: AppState) => selectJobState(state).originatorAccounts;

export default jobsSlice.reducer;

// Only for initial load of data that doesn't require authentication
// This is only done to speed things up. It is not required.
// This data should be reloaded in the pages that need it.
// Load any data a page needs in the page. Else it will not update ever.
export const loadInitialOriginatorData = createDebouncedAsyncThunk(
  `${sliceName}/initial`,
  async (_, { dispatch }) => {
    dispatch(getOriginators());
  },
  defaultTypeDebounceTimeMs,
);
