import { useAppSelector } from '@store/appStore.ts';
import { selectDefaultRoute } from '@store/reducers/meReducer.ts';
import { selectPreviousRoute } from '@store/reducers/routeReducer.ts';
import { useNavigate } from 'react-router-dom';

export default function ErrorElement() {
  const navigate = useNavigate();
  const previousRoute = useAppSelector(selectPreviousRoute);
  const defaultRoute = useAppSelector(selectDefaultRoute);
  return (
    <div className="flex min-h-screen flex-col items-center justify-center bg-red-100 text-center">
      <h1 className="text-6xl font-bold text-red-500">Error</h1>
      <p className="mt-4 text-lg text-red-700">Sorry, something went wrong.</p>
      {previousRoute === null ? (
        <button
          onClick={() => navigate(defaultRoute)}
          className="mt-8 rounded bg-red-500 px-4 py-2 font-semibold text-white hover:bg-red-600">
          Go Home
        </button>
      ) : (
        <button
          onClick={() => navigate(-1)}
          className="mt-8 rounded bg-red-500 px-4 py-2 font-semibold text-white hover:bg-red-600">
          Go Back
        </button>
      )}
    </div>
  );
}
